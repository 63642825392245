import styled from 'styled-components';
import backgroundImage from '../../img/bg.gif';
import {
    Link,
    useLocation
} from "react-router-dom";
import GalleryItem from './GalleryItem';

const Body = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    // min-width: 200px;
    width: 100%;
    font-size: 16px; 
    flex-wrap: wrap;
    // border: 5px solid red;

    // padding: 0 5px 0 20px;
    // box-shadow: inset 0 0 5px #FF0000;

    :after {
        content: " ";
        width: 200px;
    } 

`;

const preapreList = (elements) => {
    const list = []

    for (const element of elements) {
        list.push(
            <Link key={list.length.toString()} to={element.link}>
                <GalleryItem url={element.url} text={element.text} />
            </Link>
        )
    }
    return list;
}


function MainGallery(props) {
    const { elements } = props;
    return (
        <Body>
            {preapreList(elements)}
        </Body>
    )

}

export default MainGallery;