import Page from '../../components/Page';
import Gallery from '../../components/Gallery';
import images from "../galerie/json/zima.json";




function Zimowiska() {
    return (
        <Page
            body={
                <div>
                    <h1>Zimowiska Jeleniewo</h1>
                    <p>Pobliski wyciąg narciarski zadowoli fanów narciarstwa zjazdowego <a href="http://www.wosir-szelment.pl/" rel="nofollow" target="_blank">(strona WOSiR)</a></p>
                    <p>W sezonie przygotowujemy na naszym stawie otwarte i oświetlone lodowisko.</p>
                    <p>Organizujemy kuligi: 30 zł za osobę, a w tej cenie oferujemy:</p>
                    <ul>
                        <li>przejazd po malowniczej krainie (do 1,5 godziny - w zależności od warunków pogodowych i chęci uczestników)</li>
                        <li>ognisko</li>
                        <li>kiełbaska i herbata</li>
                    </ul>
                    <p>Istnieje także możliwość wynajęcie sauny w okolicy. Jest to tradycyjna sauna z której można wskoczyć bezpośrednio do jeziora.</p>
                    <h3>Wyciąg narciarski na Górze Jesionowej</h3>
                    <Gallery images={images} />
                </ div>
            }
            no
        />

    )

}

export default Zimowiska;