import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import Gallery from '../components/Gallery';
import images from "./galerie/json/konie.json";



function JazdaKonna() {
    return (
        <Page
            body={
                <div  id="content">
                    <h1>Jazda konna Augustów, Sejny, Suwałki</h1>
                    <p>Udryn i jego okolice to wymarzone warunki do <strong>jazdy konnej</strong>. Niezwykłe krajobrazy, malownicza okolica i wszystkie uroki natury zmieniające się wraz z upływem kolejnych miesięcy prezentują się jeszcze piękniej widziane z grzbietu wierzchowca. Zachęcamy do tej formy spędzania wolnego czasu. Takie połączenie sportu i rekreacji dodatkowo wpływa na dobry nastrój, o czym niejednokrotnie przekonali się goszczący u nas młodsi i starsi.</p>
                    <p>Wierzymy, że i Państwo nabierzecie przekonania do <strong>jazdy konnej</strong>, jeśli wcześniej nie mieliście Państwo z nią styczności. Nasze konie to zwierzęta spokojne, zdyscyplinowane, dobrze traktowane i odwdzięczające się zaufaniem oraz posłuszeństwem. Na swoim grzbiecie mogą zatem nosić zarówno niedoświadczonych, jak i wprawionych jeźdźców.</p>
                    <h2>Cennik</h2>
                    W naszym ośrodku posiadamy różnorodną ofertę jazdy konnej:
                    <ul>
                    <li>Jazda z instruktorem: <strong>100 zł</strong> za godzinę jazdy.</li>
                    <li>Oprowadzanka: od <strong>40 zł</strong> za 20 minut jazdy.</li>
                    <li>Wypożyczenie konia na padoku:  <strong>50 zł</strong> za godzinę jazdy.</li>
                    </ul>

                    <h2>Relaks na świeżym powietrzu podczas jazdy konnej</h2>
                    <p><strong>Jazda konna</strong> osób początkujących powinna odbywać się pod okiem fachowców i taką fachową opiekę możemy Państwu zapewnić. Nasze konie to możliwość:</p>
                    <ul>
                        <li>jazdy konnej dla osób początkujących,</li>
                        <li>nauki jazdy konnej z pomocą instruktora,</li>
                        <li>nauki jazdy konnej na padoku,</li>
                        <li>jazdy konnej dla osób doświadczonych i pewnie czujących się w siodle,</li>
                        <li>jazdy konnej zarówno w formie relaksu, jak i profesjonalnych treningów,</li>
                        <li>wyjazdów konnych w teren z instruktorem w otulinie Parku Krajobrazowego,</li>
                        <li>uczęszczania do szkółki czynnej przez cały rok ,</li>
                        <li>zorganizowania przejazdów grupowych wozem.</li>
                    </ul>
                    <p>Konie, na których będziecie Państwo mieli przyjemność jeździć, sprawdzają się na każdym etapie nauki, a także podczas doskonalenia umiejętności jeźdźców. To ważne, ponieważ możecie Państwo bez obaw skorzystać u nas z możliwości jazdy konnej na dowolnym poziomie umiejętności.</p>
                    <h2>Końmi przed siebie</h2>
                    <p>Jeśli chcecie Państwo zgłębiać tajniki sztuki jeździeckiej pod opieką wykwalifikowanych instruktorów sportu i rekreacji, lub postanowiliście Państwo sprawić taki prezent swojemu dziecku, zapraszamy do ośrodka Na Wzgórzu. Organizujemy kursy oraz szkolenia, podczas których zdobędziecie Państwo niezbędną wiedzę, lecz przede wszystkim nabędziecie lub udoskonalicie jeździeckie umiejętności.</p>
                    <p>Jazda konna to aktywność, obok której trudno przejść obojętnie. Służy w medycynie do prowadzenia hipoterapii, ma więc znaczący wpływ na rozwój jednej z gałęzi rehabilitacji. Praca z końmi to nieustanne samodoskonalenie, panowanie nad emocjami, nauka współpracy z danym wierzchowcem; nauka niezwykle wymagająca, natomiast zawsze przynosi efekty przy odpowiednim zaangażowaniu.</p>
                    <h2>Nauka jazdy konnej, która łączy pokolenia</h2>
                    <p>W pensjonacie Na Wzgórzu nuda dzieciom nie grozi, a dzięki nauce jazdy konnej będą aktywne i radosne. Jeśli uczyły się wcześniej, tutaj podszlifują umiejętności, zaś ci początkujący oswoją się ze zwierzęciem, aby bez lęku udać się na kontrolowaną przejażdżkę.</p>
                    <p>Nasze konie pozwolą Państwu i Państwa dzieciom spędzić wspólnie czas pod znakiem wesołej i ambitnej aktywności na świeżym powietrzu. Nie trzeba szukać w <strong>Jeleniewie, Augustowie, Suwałkach, czy Sejnach</strong>. Udryn i nasz ośrodek to piękna lokalizacja oraz sympatyczne, wyszkolone konie.</p>
                    <h2>Nasze konie idealne pod siodło oraz do bryczki</h2>
                    <p>Mamy łącznie 8 koni - 6 pod siodłem:</p>
                    <ul>
                        <li>Laki - 19-letni siwy wałach rasy bliżej nieokreślonej, spokojny zrównoważony, pełna profesura,</li>
                        <li>Amigo 19-letni koń podobny do Lakiego, od pary z bryczki krakowskiej,</li>
                        <li>Indi - 9-letni skarogniady wałach rasy małopolskiej, koń dla każdego,</li>
                        <li>Picolo - 4-letni tinker, 130cm w kłębie,</li>
                        <li>Ina - 9-letnia gniada klacz rasy małopolskiej dla bardziej doświadczonego jeźdźca, szybka i zwrotna,</li>
                        <li>Legat i Aviator - 8-letnie ślązaki, dobre konie chodzące pod siodłem i w bryczce,</li>
                        <li>Aron - 9-letni pstrokaty wałach rasy małopolskiej, wulkan energii, dający wiele satysfakcji świetnej zabawy, jeżeli uda się go ogarnąć.</li>
                    </ul>
                    <p>Wszystkie nasze konie chodzą w terenie.</p>
                    <section class="gallery">

                        <Gallery images={images} />

                        </section>
                </div>

            }
            no
        />

    )

}

export default JazdaKonna;