import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    Link,
    useLocation
} from "react-router-dom";
import NaszaKsiegarnia from '../../../img/nasza_ksiegarnia.png';

const Body = styled.div`
    display: flex;
    flex-direction: column;
    color: black;
    width: 200px;
    height: 150px;
    justify-content: flex-end;
    font-size: 16px; 
    margin: 10px 5px;
    box-shadow: 6px 6px 5px #4b5520;
    // border: 5px solid red;
    color: #FFFFFF;
    transition: all 0.3s;
    transition-timing-function: ease;
    :hover {
        transform: scale(1.05);
    }
`;

const Title = styled.div`
    background-color: rgba(75, 85, 32, 0.58);
    // padding: 2px 0;
    text-align: center;



`;


function GalleryItem(props) {
    const { url, text } = props;
    return (
        <Body style={{
            backgroundImage: `url(${url})`,
            backgroundSize: "cover",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: "center"

        }}>
            <Title> {text} </Title>
        </Body >
    )

}

export default GalleryItem;