import Page from '../../components/Page';
import Gallery from '../../components/Gallery';
import images from "../galerie/json/sala_rekreacyjna.json";




function SalaRekreacyjna() {
    return (
        <Page
            body={
                <div>
                    <h1>Sala rekreacyjna</h1>
                    <p>&nbsp;W tej sali znajdzecie:</p>
                    <ul>
                        <li>barek</li>
                        <li>sprzęt rekreacyjny: piłkarzyki, cymbergaj, rzutki</li>
                        <li>piankowa mata do ćwiczeń np. sportów walki i duży worek treningowy</li>
                        <li>Xbox,</li>
                        <li>stół bilardowy.</li>
                    </ul>

                    <Gallery images={images} />
                </ div>
            }
            no
        />

    )

}

export default SalaRekreacyjna;