import Page from '../../components/Page';
import Gallery from '../../components/Gallery';
import images from "../galerie/json/staw.json";




function OfertaGrupowa() {
    return (
        <Page
            body={
                <div>
                    <h1>Oferta grupowa</h1>
                    <p>W naszym pensjonacie można organizować:</p>
                    <ul>
                        <li>zielone szkoły,</li>
                        <li>kuligi,</li>
                        <li>spotkania integracyjne, obozy sportowe (np. jeździeckie, sztuk walki, narciarskie itp.)</li>
                    </ul>
                    <p>Rezerwacja całego obiektu dla grup zorganizowanych (44 miejsca noclegowe + dostawki): <br /> &nbsp; <strong>2.300 zł</strong> za dobę.</p>
                </ div>
            }
            no
        />

    )

}

export default OfertaGrupowa;