import styled from 'styled-components';
import Page from '../../components/Page';
import '../../styles/main.css';
import '../../styles/home.css';
import { useState } from 'react';

import { SRLWrapper } from "simple-react-lightbox";

const Body = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    // min-width: 200px;
    width: 100%;
    font-size: 16px; 
    flex-wrap: wrap;
    // border: 5px solid red;

    // padding: 0 5px 0 20px;
    // box-shadow: inset 0 0 5px #FF0000;

    // :after {
    //     content: "";
    //     flex: auto;
    // } 

`;

const imgStyle = {
    height: 150,
    width: 220,
    objectFit: "cover",
    padding: 5
}


const generateGallery = (images, style) => (
    images.map((img) => (
        <a href={img.url} data-lightbox="roadtrip">
            <img
                src={img.url}
                alt={img.title}
                style={{...imgStyle, ...style}}
            />
        </a>
    ))
)

function Gallery({ images, style }) {
    console.log("tu", generateGallery(images))
    return (
        <SRLWrapper>
            {generateGallery(images, style)}
        </SRLWrapper>
    )

}

export default Gallery;