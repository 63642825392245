import styled from 'styled-components';
import backgroundImage from '../../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    Link,
    useLocation
} from "react-router-dom";
import NaszaKsiegarnia from '../../img/nasza_ksiegarnia.png';
import mpr from '../../img/mpr.png';
import './sidebar.css';

const Body = styled.div`
    display: inline-block
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: black;
    // min-width: 200px;
    max-width: 220px;
    font-size: 16px; 
    padding: 0 5px 0 20px;
    // box-shadow: inset 0 0 5px #FF0000;

`;


function Sidebar(props) {

    return (
        <Body>
            <img src={NaszaKsiegarnia} alt={'Ksiegarnia Kruk'} style={{ maxWidth: "100%", paddingTop: 0 }} />
            <img src={mpr} alt={'Mpr'} style={{ maxWidth: "100%",  paddingTop: "15px" }} />
            <a href="https://www.weselezklasa.pl/ogloszenia-weselne/pensjonat-na-wzgorzu,48760/" target="_blank" title="Pensjonat Na Wzgórzu sala na wesele, sala z noclegiem, hotel dla gości"><img style={{ maxWidth: "100%", paddingTop: "15px" }} src="/img/weselezklasa.png" alt="Pensjonat Na Wzgórzu sala na wesele, sala z noclegiem, hotel dla gości" border="0" /></a>
        </Body>
    )

}

export default Sidebar;