import styled from 'styled-components';
import backgroundImage from '../../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import {
    Link,
    useLocation
} from "react-router-dom";


const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    max-width: 960px;
    min-width: 200px;
    font-size: 16px; 
    padding: 0px;
    // border: 5px solid red;
    margin: 5px auto;
    width: 100vw;
`;


const Ul = styled.ul`
    list-style: none;
    margin:  0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    // display: grid;
    // grid-template-columns: repeat(auto-fill,minmax(150px, 1fr));
    flex-direction: row;
    margin: auto;   
    width: 100%;
    justify-content: space-between;
    #box-shadow: inset 0 0 5px #FF0000;
    box-sizing: border-box;
    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }

    @media only screen and (max-width: 900px) {
        :after {
            content: "";
            flex: auto;
        }    
    }


`;

const Li = styled.li`
    list-style: none;
    border-radius: 10px;
    padding: 6px 5px;
    letter-spacing: 0.8px;
    margin: 5px;
    :hover {
        background-color: #98b452;
        color: #ffffff;
    }
    font-size: 17px;

    &.selected {
        background-color: #98b452;
        color: #ffffff;
    }
   
`;

const preapreList = (elements, location) => {
    const list = []

    for (const element of elements) {
        console.log(location.pathname === element.address, location, element.address, location.pathname === element.address ? "active" : "")
        list.push(
            <Link key={list.length.toString()} to={element.address}>
                <Li className={location.pathname === element.address ? "selected" : ""}>
                    {element.name}
                </Li>
            </Link>
        )
    }
    return list;
}

function Menu(props) {
    const { body, elements } = props
    const location = useLocation();

    return (
        <Body>
            <Ul>
                {preapreList(elements, location)}
            </Ul>
        </Body>
    )

}

export default Menu;