import React from "react";
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Home, Kontakt, Okolica, Galeria, Pokoje, Atrakcje, JazdaKonna, SalaImprezowa } from './pages'
import styled from 'styled-components'

import './App.css'
import Dojazd from "./pages/dojazd";

const Body = styled.div`
  display: flex;
  min-height: 100%;
  color: #4b5520;
  max-width: 960px
  margin: 0;
  flex-direction: column;
  background-color: #F6F1D8; 
`;

export default function App() {
  return (
    <body>
      <Body>
        <Router>
          <Switch>
            <Route exact path="/kontakt">
              <Kontakt />
            </Route>
            <Route exact path="/users">
              <Users />
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Okolica">
              <Okolica />
            </Route>            
            <Route path="/galeria" >
              <Galeria />
            </Route>
            <Route exact path="/pokoje" >
              <Pokoje />
            </Route>
            <Route exact path="/sala-imprezowa" >
              <SalaImprezowa />
            </Route>
            <Route exact path="/dojazd" >
              <Dojazd />
            </Route>
            <Route path="/atrakcje" >
              <Atrakcje />
            </Route>
            <Route exact path="/jazda-konna" >
              <JazdaKonna />
            </Route>
          </Switch>
        </Router>
      </Body>
    </body>

  );
}



function About() {
  return <h2>About</h2>;
}

function Users() {
  const location = useLocation();
  console.log(location.pathname)
  if (location.pathname === '/users')
    return <h3>Users</h3>;

  return <h2>Users</h2>;
}