import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import Gallery from '../components/Gallery';
import images from "./galerie/json/sala_weselna.json";


const s = () => { let k = ""; for (let i = 0; i < 9999; i++) k += "gal \n"; return k }


function SalaImprezowa() {
    return (
        <Page
            body={
                <div>
                    <h1>Imprezy okolicznościowe Jeleniewo</h1>
                    <p><div style={{float: "right", marginLeft: "10px"}}><Gallery style={{height: 167, width: 250}} images={[{"url": "/img/galeria/salaWeselna/sala_weselna_18.jpg"}]} /></div>Każdego roku w życiu Państwa i Państwa rodziny zdarzają się okazję do wspólnego ucztowania. Są to mniejsze i większe okazje, które dają impuls do wspólnego spędzenia czasu w radosnej atmosferze, przy dobrym jedzeniu i klimatycznej muzyce. Chcąc jednak w pełni uczestniczyć w tych <strong>imprezach okolicznościowych</strong> warto trud ich przygotowań powierzyć osobom, które z przyjemnością przejmą od Państwa wszystkie zadania.</p>
                        <p>Magia miejsca, w którym zechcecie Państwo zorganizować dowolną imprezę okolicznościową to duża część sukcesu całego przedsięwzięcia. Ważne są zarówno wystrój lokalu, jak i jego otoczenie, a także dodatkowe atrakcje, które pozwolą zadowolić najbardziej wymagających gości.</p>
                        <h2>Komunie, czyli z gamy imprez okolicznościowych</h2>
                        <p>Niewątpliwą zaletą urządzania imprez okolicznościowych w lokalu jest komfort psychiczny i świadomość, że zespół profesjonalistów od początku do końca imprezy czuwa i aktywnie dba o niezakłócony jej przebieg. Możecie być zatem Państwo pewni, że jedzenie trafi na stoły gorące oraz pięknie podane, dyskretna obsługa zaoferuje niezbędną pomoc, a goście wyjdą oczarowani.</p>
                            <p title="wesela">W trosce o Państwa wolny czas i komfort bycia gościem na własnym przyjęciu organizujemy imprezy okolicznościowe:</p>
                            <ul>
                                <li>chrzciny,</li>
                                <li>komunie,</li>
                                <li>studniówki,</li>
                                <li>jubileusze,</li>
                                <li>imprezy firmowe,</li>
                                <li>bale sylwestrowe,</li>
                                <li>kinderbale,</li>
                                <li>imieniny,</li>
                                <li>urodziny.</li>
                            </ul>
                            <p>Bez względu na porę roku zapewnimy pogodny nastrój i radość wspólnego świętowania</p>
                            <h2>Dobrze wyposażona sala na imprezy okolicznościowe</h2>
                            <p>W naszej sali bankietowej znajdziecie Państwo wszystko, co niezbędne do zorganizowania profesjonalnej imprezy okolicznościowej. Proponujemy Państwu</p>
                                <ul>
                                    <li>udekorowanie i nagłośnienie sali bankietowej,</li>
                                    <li>atrakcje dla dzieci na dużym ciekawym placu zabaw,</li>
                                    <li>inne formy aktywności zależne od pory roku.</li>
                                </ul>
                                <p>Jednym z najważniejszych aspektów danej imprezy okolicznościowej jest menu. Kuchnia to nasza domena, a efekty starań zadowolą nawet najbardziej wymagających smakoszy. Po wcześniejszych ustaleniach przygotowujemy dania zgodnie z Państwa wytycznymi. Istnieje możliwość dostosowania potraw do potrzeb dzieci, wegetarian, czy osób alergicznych. Wyczucie smaku to dla nas pojęcie wielowarstwowe.</p>
                                <h2>Imprezy okolicznościowe blisko Suwałk, Sejn, Augustowa</h2>
                                <p>Opracowując niegdyś pierwszą propozycję <strong>organizowania imprez okolicznościowych</strong> w naszej sali bankietowej kierowaliśmy się tymi samymi wytycznymi, które mamy do dziś. Priorytetem jest dla nas nowoczesna pełnowartościowa usługa w przystępnej cenie. Dzięki niesłabnącemu zainteresowaniu i liczbie poleceń naszej sali widzimy, że jest to właściwa strategia.</p>
                                    <p>Zachęcamy do skorzystania z naszych usług osoby z:</p>
                                    <ul>
                                        <li>Jeleniewa,&nbsp;</li>
                                        <li>Sejn,</li>
                                        <li>Suwałk,</li>
                                        <li>Augustowa,</li>
                                    </ul>
                                    <p>okolic Szelmentu, oraz bliższych i dalszych zakątków Polski. Wyróżnia nas profesjonalizm na każdym etapie współpracy.</p>
                                    <Gallery images={images} />
		</div>
            }
            no
        />

    )

}

export default SalaImprezowa;