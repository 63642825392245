import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import '../styles/main.css';
import '../styles/home.css'
import { GaleriaKonie, GaleriaSala, GaleriaWiaty, GaleriaPokoje, GaleriaSalaRekreacyjna, GaleriaAgroturystyka, GaleriaPlacZabaw, GaleriaStaw, GaleriaZima, GaleriaDomek } from './galerie';
import { Link, Route, useRouteMatch, Router } from 'react-router-dom';
import MainGallery from '../components/MainGallery';
import GaleriaOkolica from './galerie/galeriaOkolica';



const galerie = [
    {
        url: "/img/galeria/thumbnails/agroturystyka.jpg",
        text: "Agroturystyka",
        link: `/galeria/agroturystyka`
    },
    {
        url: "/img/galeria/thumbnails/wiaty.jpg",
        text: "Wiaty",
        link: `/galeria/wiaty`

    },
    {
        url: "/img/galeria/thumbnails/konie.jpg",
        text: "Konie",
        link: "/galeria/konie"
    },
    {
        url: "/img/galeria/thumbnails/domek.jpg",
        text: "Domek w lesie",
        link: "/galeria/domek"

    },    
    {
        url: "/img/galeria/thumbnails/okolica.jpg",
        text: "Okolica",
        link: "/galeria/okolica"

    },
    {
        url: "/img/galeria/thumbnails/plac_zabaw.jpg",
        text: "Plac zabaw",
        link: "/galeria/placZabaw"
    },
    {
        url: "/img/galeria/thumbnails/pokoje.jpg",
        text: "Pokoje",
        link: "/galeria/pokoje"
    },
    {
        url: "/img/galeria/thumbnails/sala_rekreacyjna.jpg",
        text: "Sala rekreacyjna",
        link: "/galeria/salaRekreacyjna"

    },
    {
        url: "/img/galeria/thumbnails/sala_weselna.jpg",
        text: "Sala weselna",
        link: `/galeria/salaWeselna`

    },
    {
        url: "/img/galeria/thumbnails/staw.jpg",
        text: "Staw",
        link: "/galeria/staw"
    },
    {
        url: "/img/galeria/thumbnails/zima.jpg",
        text: "Zima",
        link: "/galeria/zima"    
    },
    
    
];


function Galeria(props) {
    const { url, path } = useRouteMatch()

    console.log(path);

    return (
        <div>
            <Route exact path={`${path}`}>
                <Page
                    body={
                        <div>
                            {/* <Link to={`${path}/konie`}>
                                Example topic
                            </Link>
                            <Link to={`${path}/pokoje`}>
                                Example topic
                            </Link> */}
                                   <MainGallery elements={galerie}/>

                        </div>
                    }
                    no
                />
            </Route>
            <Route exact path={`${path}/konie`}>
                <GaleriaKonie />
            </Route>
            <Route path={`${path}/salaWeselna`}>
                <GaleriaSala />
            </Route>
            <Route path={`${path}/wiaty`}>
                <GaleriaWiaty />
            </Route>
            <Route path={`${path}/pokoje`}>
                <GaleriaPokoje />
            </Route>
            <Route path={`${path}/salaRekreacyjna`}>
                <GaleriaSalaRekreacyjna />
            </Route>
            <Route path={`${path}/agroturystyka`}>
                <GaleriaAgroturystyka />
            </Route>
            <Route path={`${path}/placZabaw`}>
                <GaleriaPlacZabaw />
            </Route>
            <Route path={`${path}/staw`}>
                <GaleriaStaw />
            </Route>
            <Route path={`${path}/zima`}>
                <GaleriaZima />
            </Route>
            <Route path={`${path}/okolica`}>
                <GaleriaOkolica />
            </Route>
            <Route path={`${path}/domek`}>
                <GaleriaDomek />
            </Route>
        </div>

    )

}

export default Galeria;