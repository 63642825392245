import styled from 'styled-components';
import Page from '../../components/Page';
import '../../styles/main.css';
import '../../styles/home.css';
import { useState } from 'react';
import Gallery from '../../components/Gallery';
import images from "./json/wiaty.json"

const Body = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: black;
    // min-width: 200px;
    width: 100%;
    font-size: 16px; 
    flex-wrap: wrap;
    // border: 5px solid red;

    // padding: 0 5px 0 20px;
    // box-shadow: inset 0 0 5px #FF0000;

    // :after {
    //     content: "";
    //     flex: auto;
    // } 

`;


function GaleriaWiaty({ match }) {
    return (
        <Page
            body={
                <Body>
                    <Gallery images={images} />
                </Body>
            }
            no
        />

    )

}

export default GaleriaWiaty;