import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import Gallery from '../components/Gallery';
import images from "./galerie/json/pokoje.json";


const s = () => { let k = ""; for (let i = 0; i < 9999; i++) k += "gal \n"; return k }


function Pokoje() {
    return (
        <Page
            body={
                <div>
                    <div>
                        <h1>Pokoje Jeleniewo</h1>
                        <h2>Komfortowe pokoje w przystępnych cenach</h2>
                        <p>Jednodniowy pobyt w pensjonacie Na Wzgórzu to zdecydowanie zbyt mało. Aby poznać uroki tego miejsca i jego okolicy warto zaplanować dłuższy wyjazd, a wtedy będzie szansa odkryć wszystkie tajemnice tego urokliwego zakątka. Każdego dnia możemy Państwu zapewnić moc atrakcji, natomiast ważny jest także odpoczynek, w związku z tym <strong>oddajemy do dyspozycji pokoje</strong>, które spełniają wymogi komfortu przy rozsądnych cenach.</p>
                        <p>Przygotowując <strong>pokoje w naszym pensjonacie</strong> zadbaliśmy o każdy szczegół, który może mieć wpływ na wygodę i jakość nocowania, a także poziom relaksu w ciągu dnia. Zawsze otwarci na Państwa sugestie i potrzeby dbamy o zapewnienie wszystkiego, co czyni sam pokój i jego wyposażenie najbardziej funkcjonalnym.</p>
                        <h3 style={{ textAlign: "left" }}><span style={{ textDecoration: "underline" }}>Oferta hotelowa</span></h3>
                        <div>
                            <table style={{ width: "80%", margin: "20px auto" }} border="0" align="center">
                                <tbody>
                                    <tr>
                                        <td style={{ backgroundColor: "#d4dea6" }}><strong>pokój (ilość osób)</strong></td>
                                        <td style={{ backgroundColor: "#d4dea6" }} align="center" valign="middle"><strong>cena za noc</strong></td>
                                    </tr>
                                    <tr>
                                        <td>pokój dwuosobowy</td>
                                        <td align="center" valign="middle">160 zł</td>
                                    </tr>
                                    <tr>
                                        <td>pokój trzyosobowy</td>
                                        <td align="center" valign="middle">180 zł</td>
                                    </tr>
                                    <tr>
                                        <td>pokój trzyosobowy z tarasem</td>
                                        <td align="center" valign="middle">190 zł</td>
                                    </tr>
                                    <tr>
                                        <td>pokój czteroosobowy</td>
                                        <td align="center" valign="middle">210 zł</td>
                                    </tr>
                                    <tr>
                                        <td>pokój pięcioosobowy</td>
                                        <td align="center" valign="middle">260 zł</td>
                                    </tr>
                                    <tr>
                                        <td>pokój dwuosobowy na poddaszu</td>
                                        <td align="center" valign="middle">110 zł</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>W przypadku noclegu jednodobowego jednej osoby: 100 zł</p>
                        <p>Rezerwacja całego obiektu dla grup zorganizowanych: 2.400 zł za dobę.</p>
                        <p>Dostawka łóżka (lub łóżeczka dla dziecka) na jedną dobę: 40 zł<br />Więcej niż jedna doba: 30 zł.</p>
                        <p><strong>Doba hotelowa: od 14.00 - do 12.00 dnia następnego.</strong></p>
                        <h3><span style={{ textDecoration: "underline" }}>Wyżywienie</span></h3>
                        <p>Istnieje możliwość wynajmu pokoi wraz z wyżywieniem. Zależy to jednak od stopnia obłożenia obiektu.Produkty spożywcze pochodzą z gospodarstw rolnych okolicznych sąsiadów.</p>
                        <p>Ceny posiłków:</p>
                        <ul>
                            <li>śniadanie: 25 zł /szwedzki stół/</li>
                            <li>obiadokolacja: 30 zł /zupa, II danie, deser/</li>
                        </ul>
                        <h2>Jeszcze o noclegach</h2>
                        <p>Biorąc pod uwagę różną liczbę osób odwiedzających nas w ramach poszczególnych pobytów, zorganizowaliśmy nasze pokoje pod kątem noclegów tak, aby można je było rozdysponować ze względu na potrzeby gości. Znajdziecie tu zatem Państwo:</p>
                        <ul>
                            <li>pokój trzyosobowy bez tarasu,</li>
                            <li>pokój trzyosobowy z tarasem,</li>
                            <li>pokój czteroosobowy,</li>
                            <li>pokój pięcioosobowy,</li>
                            <li>pokoje z łazienkami i odbiornikiem TV,</li>
                            <li>pokoje z dostępem do kuchni,</li>
                            <li>pokoje z opcją dostawki dla dziecka,</li>
                            <li>pokoje z opcją zamówienia stałych posiłków.</li>
                        </ul>
                        <p>Augustów, Suwałki, Sejny, Jeleniewo to tylko niektóre rodzinne miasta naszych dotychczasowych gości. Blisko do nas także z Szelmentu. Zapraszamy także Państwa, szykując serdeczne powitanie i wyjątkowo spędzony czas.</p>
                        <h2>Pokoje do wynajęcia</h2>
                        <p>Zachęcając do skorzystania z naszej propozycji noclegów mamy świadomość, jak ważne jest dbanie o pokoje, nieustanne ich doglądanie, oraz wyposażanie w niezbędne elementy. Będziecie mieć zatem Państwo do dyspozycji nie tylko to co w standardzie, lecz przy dłuższym pobycie damy Państwu możliwość nieodpłatnego skorzystania z wielu dodatkowych atrakcji.</p>
                        <p>Wyposażenie ośrodka, z którego możecie Państwo korzystać wynajmując pokoje to między innymi:</p>
                        <ul>
                            <li>kuchnia z nowoczesnym sprzętem AGD, ale także tradycyjna, opalana drewnem płyta,</li>
                            <li>sala rekreacyjna z:
<ul>
                                    <li>barkiem,</li>
                                    <li>stołem do ping-ponga,</li>
                                    <li>piłkarzykami,</li>
                                    <li>cymbergajem,</li>
                                    <li>matami do treningu sztuk walki,</li>
                                    <li>workiem treningowym,</li>
                                    <li>rzutkami,</li>
                                    <li>Xbox-em,</li>
                                    <li>stołem bilardowym,</li>
                                </ul>
                            </li>
                            <li>pralka,</li>
                            <li>suszarka do włosów,</li>
                            <li>czajnik elektryczny,</li>
                            <li>sejf.</li>
                        </ul>
                        <p>Z taką samą starannością dbamy o otoczenie ośrodka, możecie więc Państwo wynajmując pokoje liczyć na atrakcje w postaci:</p>
                        <ul>
                            <li>miejsca na grill i ognisko,</li>
                            <li>altany na wodzie,</li>
                            <li>boiska do piłki nożnej,</li>
                            <li>wielkiego placu zabaw,</li>
                            <li>możliwości wypożyczenia
<ul>
                                    <li>łodzi wiosłowej,</li>
                                    <li>kajaków,</li>
                                    <li>wędek,</li>
                                    <li>rowerów.</li>
                                </ul>
                            </li>
                        </ul>
                        <h2>Noclegi i wszystko co z nimi związane</h2>
                        <p>Sen jako najskuteczniejsza forma wypoczynku i regeneracji zasługuje na odpowiednią oprawę i scenerię,oraz warunki zbliżone do ideału. Chcemy, abyście czuli się Państwo jak w domu, a nawet lepiej, biorąc pod uwagę piękne widoki za oknem, których br /akuje w wielkomiejskim świecie.</p>
                        <p>Ośrodek Na Wzgórzu to propozycja noclegów bliskich tradycji pod względem komfortu snu, ale też w zgodzie z nowoczesnością w kontekście poziomu i wystroju pokojów.</p>
                        <h2>Galeria</h2>
                        <p></p><section class="gallery">

                        <Gallery images={images} />

                        </section><p></p>
                    </div>
                </div>
            }
            no
        />

    )

}

export default Pokoje;