import styled from 'styled-components';
import backgroundImage from '../../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../img/carousel/1.jpg';
import img2 from '../../img/carousel/2.jpg';
import img3 from '../../img/carousel/3.jpg';
import logo from '../../img/logo.png';
import Menu from '../Menu';
import Sidebar from '../Sidebar'
const HeaderContainer = styled.div`
  display: flex;
  overflow: auto;
  width: 100%;
  background-image: url(${backgroundImage});
  background-attachment: scroll;
  background-size: 130% auto;
  background-position: center 50%;
  background-repeat: no-repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;

`;

const Slider = styled.div`
    width: auto;
    max-width: 960px;
    margin-bottom: 10px;
`;



const Header = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    min-width: 200px;
    font-size: 16px;
    margin: 0px auto 0 auto;
    position:relative;
`;

const MainContainer = styled.div`
    max-width: 960px;
    min-width: 200px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    // border: 5px solid red;

    // box-shadow: inset 0 0 5px #FF0000;
    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding: 2px 10px ;
    #box-shadow: inset 0 0 5px #FF0000;
    text-align: justify;
    max-width: 960px;
    min-height: 350px;
    // border: 5px solid red;
    width: 100%;

    min-width: 200px;
    font-size: 16px; 
    // margin: auto;
    @media only screen and (max-width: 400px) {
        //width: 1000px;
    }
    `;

const Logo = styled.img`
    position: absolute;
    display: block;

    margin-left: auto;
    margin-right: auto;
    overflow: visible;
    z-index: 100; 
    max-width:100%; 
    min-width: 40px; 
    height: auto;
    width: 22%;
    margin: 1% 20px;
`

const Body = styled.div`
    font-size: 20 important!;
    display: flex;
    background-color: #F6F1D8;
    flex-direction: column;
    @media only screen and (max-width: 400px) {
        :nth-child(1) { order: 1; }
        :nth-child(2) { order: 3; }
    }
`;

const Footer = styled.div`
    // max-width: 960px;
    min-width: 200px;
    min-height: 80px;
    margin-top: 30px;
    width: 100%;
    // margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: end;
    background-color: #99B452;
    // border: 5px solid red;

    // box-shadow: inset 0 0 5px #FF0000;
    @media only screen and (max-width: 400px) {
        flex-direction: column;
    }
`;

const elements = [
    {
        address: "/",
        name: "Strona główna"
    },
    {
        address: "/atrakcje",
        name: "Atrakcje"
    },
    {
        address: "/sala-imprezowa",
        name: "Sala imprezowa"
    },
    {
        address: "/pokoje",
        name: "Pokoje"
    },
    {
        address: "/jazda-konna",
        name: "Jazda konna"
    },
    {
        address: "/okolica",
        name: "Okolica"
    },
    {
        address: "/galeria",
        name: "Galeria"
    },
    {
        address: "/dojazd",
        name: "Dojazd"
    },
    {
        address: "/kontakt",
        name: "Kontakt"
    }

]


function Page(props) {
    const { body } = props
    return (
        <Body>
            <Menu elements={elements} />
            <HeaderContainer>
                <Header>
                    <Slider>
                        <Logo src={logo} />

                        <Carousel
                            renderThumbs={() => { }}
                            statusFormatter={() => { }}
                            onChange={(info) => { console.log(info) }}
                        >
                            <div>
                                <img src={img1} />
                            </div>
                            <div>
                                <img src={img2} />
                            </div>
                            <div>
                                <img src={img3} />
                            </div>
                        </Carousel>
                    </Slider>
                </Header>

            </HeaderContainer>

            <MainContainer>
                <Content>
                    {body}
                </Content>
                <Sidebar />
            </MainContainer>
            <Footer>
                Copyright © Na Wzgórzu
            </Footer>

        </Body>
    )

}

export default Page;