import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import '../styles/main.css';
import '../styles/home.css'



function Okolica() {
    return (
        <Page
            body={
                <div>
                    <div>
                        <h2>Informacje użyteczne i atrakcje w okolicy</h2>
                        <ul>
                            <li>Przejazdy bryczką</li>
                            <li>Sesja zdjęciowa z końmi</li>
                            <li>Lot helikopterem w weekendy (400 zł za 15 minut lotu)</li>
                            <li>Najbliższe sklepy i bankomat w Jeleniewie (5 km)</li>
                            <li>Centrum Handlowe w Suwałkach (16 km)</li>
                            <li>Aquapark (16 km)</li>
                            <li>Stok narciarski z wyciągami - WOSiR Szelment (4 km)</li>
                            <li>Jezioro Hańcza (12 km)</li>
                            <li>Kościół w Jeleniewie - msze o godz. 8.00, 10.00 i 12.00</li>
                            <li>Boisko - Orlik w Jeleniewie (5 km)</li>
                            <li>Restauracja Pod Jelonkiem - <a href="http://www.podjelonkiem.pl/index2.php" rel="nofollow" target="_blank">strona lokalu</a> (5 km)</li>
                        </ul>
                        <h2>Atrakcje w okolicy</h2>
                        <ul>
                            <li><a href="http://www.aquapark.suwalki.pl/" rel="nofollow" target="_blank">Aquapark w Suwałkach</a></li>
                            <li><a href="http://osir.suwalki.pl/index.php/menu-obiektow-osiru/sciana-wspinaczkowa//" rel="nofollow" target="_blank">Ścianka wspinaczkowa w Suwałkach</a></li>
                            <li><a href="http://www.wigry.win.pl/" rel="nofollow" target="_blank">Wigierski Park Narodowy</a></li>
                            <li><a href="http://www.spk.org.pl/" rel="nofollow" target="_blank">Suwalski Park Krajobrazowy</a></li>
                            <li><a href="http://www.wigry.win.pl/mw/index.php" rel="nofollow" target="_blank">Muzeum Wigier w Starym Folwarku</a></li>
                            <li><a href="http://www.piotrmalczewski.com/" rel="nofollow" target="_blank">Chlewogaleria Piotra Malczewskiego w Budzie Ruskiej</a></li>
                            <li><a href="http://www.sturnusek.republika.pl/" rel="nofollow" target="_blank">Wirtualna galeria Huberta Stojanowskiego - zdjęcia Suwalczyzny, także z powietrza</a></li>
                            <li><a href="http://www.dabrowka.suwalki.pl/" rel="nofollow" target="_blank">Wyciąg narciarski na Dąbrówce koło Suwałk</a></li>
                            <li><a href="http://www.wosir-szelment.pl/" rel="nofollow" target="_blank">Ośrodek Narciarski WOSiR koło jeziora Szelment na Górze Jesionowej</a></li>
                            <li><a href="http://zajazd-rudziewicz.pl/index.php?akt_str=safari1" rel="nofollow" target="_blank">Park Safari koło Gołdapi</a></li>
                            <li><a href="http://www.ogrodbajek.pl/" rel="nofollow" target="_blank">Ogród Bajek "Sowa" koło Kaletnika</a></li>
                            <li><a href="http://www.augustowska.pl/" rel="nofollow" target="_blank">Wigierska Kolej Wąskotorowa w Płocicznie</a></li>
                            <li>Klasztor Kamedułów w Wigrach</li>
                            <li>Góra Zamkowa w Szurpiłach</li>
                            <li>Cmentarzysko Jaćwingow w Szwajcarii koło Suwałk</li>
                            <li>Jezioro Hańcza, najgłębsze w Polsce</li>
                            <li>Głazowiska: Bachanowo i Rutka, relikty działania lodowca</li>
                            <li>Stańczyki, jedne z najwyższych w Polsce wiaduktów</li>
                            <li>Molenna staroobrzędowców w Wodziłkach</li>
                            <li>Punkty widokowe w Smolnikach, Gulbieniszkach, Rutce</li>
                            <li>Kompleks bunkrów w Bakałarzewie</li>
                        </ul>
                        <p>Z racji bliskości granicy z Litwą istnieje możliwość zorganizowania Państwu wycieczek do Wilna, Trok czy Druskiennik</p>



                    </div>
                </div>
            }
            no
        />

    )

}

export default Okolica;