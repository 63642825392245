import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import "./css/kontakt.css"


function Kontakt() {
    return (
        <Page
            body={
                <div style={{width: "100%"}}>
                    <h1>Kontakt</h1>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: 'center' }}>

                        <center>
                            <h3 style={{ textAlign: "center" }}><strong>Ośrodek Na Wzgórzu</strong></h3>

                            <p>Jeżeli są Państwo zainteresowani naszą ofertą, to prosimy o kontakt telefoniczny:<br />(+48) <a href="tel:+48508385889">508 385 889</a>,&nbsp;<a href="tel:+48502160583">502 160 583</a>, <a href="tel:+48508385996">508 385 996</a></p>
                            <p>Dane do przelewu:<br />Odbiorca: Krzysztof Filipowicz<br />Nr konta: 07 1140 2017 0000 4202 0766 2069</p>
                            <p>Przelew zza granicy: PL07 1140 2017 0000 4202 0766 2069</p>
                        </center>
                        <div>
                            <p style={{ textAlign: "center" }}>Udryn 12a<br />16-404 Jeleniewo</p>
                            <p style={{ textAlign: "center" }}>tel. <a href="tel:+48508385889">508 385 889</a><br />e-mail: <a href="mailto:ksiegarnie.kruk@op.pl">ksiegarnie.kruk@op.pl</a></p>
                        </div>
                    </div>
                </div>
            }
            no
        />

    )

}

export default Kontakt;