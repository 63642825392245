import Page from '../../components/Page';
import Gallery from '../../components/Gallery';
import images from "../galerie/json/staw.json";




function AktywnyWypoczynek() {
    return (
        <Page
            body={
                <div>
                    <h1>Rowery, kajaki i wędkarstwo</h1>
                    <p>Do dyspozycji gości:</p>
                    <ul>
                        <li>kajaki,</li>
                        <li>wędki,</li>
                        <li>łódź wiosłowa,</li>
                        <li>rowery.</li>
                    </ul>
                    <p>W przypadku dłuższego pobytu wszystkie powyższe elementy bez dodatkowych opłat. Jeżeli pobyt jest krótszy - możliwość korzystania za dodatkową opłatą.</p>
                    <p>Istnieje możliwość transportu kajaków nad okoliczne jeziora i rzeki (oferta do uzgodnienia w właścicielem obiektu)</p>

                    <Gallery images={images} />
                </ div>
            }
            no
        />

    )

}

export default AktywnyWypoczynek;