import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';


const s = () => { let k = ""; for (let i = 0; i < 9999; i++) k += "gal \n"; return k }


function Dojazd() {
    return (
        <Page
            body={
                <div>
                    <h1>Dojazd</h1>
                    <p>Aby do nas dojechać należy kierować się do miejscowości Jeleniewo, w której skręcamy w kierunku Becejł. Mijamy drogę do ośrodka narciarskiego Szelment cały czas jadąc w kierunku Becejł i we wsi Udryn skręcamy w lewo w żwirową drogę.</p>
                    <p>Umiejscowienie naszego obiektu na mapie:</p>
                    <p style={{ textAlign: "center" }}><iframe title="Mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d230470.34441353023!2d22.832330352831736!3d54.17951849823923!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e11c7b16172881%3A0x65c31004be114d9b!2sPensjonat%20na%20Wzg%C3%B3rzu%20-%20sala%20weselna%2C%20noclegi%2C%20agroturystyka!5e0!3m2!1spl!2spl!4v1627515969046!5m2!1spl!2spl" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy"></iframe></p>
                </div>
            }
            no
        />

    )

}

export default Dojazd;