import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Route, useRouteMatch } from "react-router";
import MainGallery from "../components/MainGallery";
import Page from '../components/Page';
import AktywnyWypoczynek from "./atrakcje/aktywnyWypoczynek";
import OfertaGrupowa from "./atrakcje/ofertaGrupowa";
import SalaRekreacyjna from "./atrakcje/salaRekreacyjna";
import Zimowiska from "./atrakcje/zimowiska";

const oferta = [
    {
        url: "/img/galeria/salaRekreacyjna/sala_rekreacyjna_01.jpg",
        text: "Sala rekreacyjna",
        link: `/atrakcje/sala-rekreacyjna`
    },
    {
        url: "/img/galeria/staw/staw_04.jpg",
        text: "Aktywny wypoczynek",
        link: `/atrakcje/aktywny-wypoczynek`

    },
    {
        url: "/img/galeria/salaRekreacyjna/sala_rekreacyjna_05.jpg",
        text: "Oferta grupowa",
        link: `/atrakcje/oferta-grupowa`

    },
    {
        url: "/img/galeria/zima/zima_01.jpg",
        text: "Zimowiska",
        link: "/atrakcje/zimowiska"
    },
    {
        url: "/img/galeria/salaWeselna/sala_weselna_16.jpg",
        text: "Sala imprezowa",
        link: `/sala-imprezowa`
    },
    {
        url: "/img/galeria/konie/konie_01.jpg",
        text: "Jazda konna",
        link: `/jazda-konna`
    },
]

function Atrakcje() {
    const { url, path } = useRouteMatch()

    return (
        <div style={{ width: "100%" }}>
            <Route exact path={`${path}`}>
                <Page
                    body={
                        <div style={{ width: "100%" }}>
                            <div>
                                <h1>Atrakcje</h1>
                            </div>
                            <MainGallery elements={oferta} />

                        </div>
                    }
                    no
                />
            </Route>
            <Route exact path={`${path}/sala-rekreacyjna`}>
                <SalaRekreacyjna />
            </Route>
            <Route exact path={`${path}/aktywny-wypoczynek`}>
                <AktywnyWypoczynek />
            </Route>
            <Route exact path={`${path}/zimowiska`}>
                <Zimowiska />
            </Route>
            <Route exact path={`${path}/oferta-grupowa`}>
                <OfertaGrupowa />
            </Route>


        </div>


    )

}

export default Atrakcje;