import styled from 'styled-components';
import backgroundImage from '../img/bg.gif';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Page from '../components/Page';
import '../styles/main.css';
import '../styles/home.css'



function Home() {
    return (
        <Page
            body={
                <div>
                    <h1>Noclegi Jeleniewo | Pensjonat Suwałki, Augustów</h1>
                    <p>Witamy Państwa serdecznie w ośrodeku agroturystycznym "Na Wzgórzu"</p>
                    <p>Znajdujemy się tam, gdzie piękno natury można podziwiać o każdej porze roku, a bliskość lasów i jezior pozwala odetchnąć od zgiełku codziennego życia. Otoczenie w postaci takich uroków przyrody pozwala nam w myśl idei agroturystyki proponować wymarzoną formę relaksu bez względu na czas, jaki zechcecie Państwo u nas spędzić.</p>
                    <p>Wieś Udryn leży w otulinie Suwalskiego Parku Krajobrazowego, co także stanowi o walorach tego miejsca. Malownicze tereny doceniło wielu naszych stałych gości, a także tych, którzy trafiają do nas po raz pierwszy. Każdy czuje się tu wyjątkowo, ponieważ znajduje to, czego w agroturystyce nie powinno zabraknąć – kontaktu z nieskażoną naturą. O wschodzie słońca słychać dźwięki żurawi, a na okolicznych wzgórzach i w pobliżu zabudowań zobaczyć można bobry. Ośrodek jest na terenie chronionym Natura 2000.</p>
                    <h2>Agrowczasy, noclegi...</h2>
                    <p>"Na Wzgórzu" prowadzimy od wielu lat i wiemy, jak ważne jest stałe wzbogacanie zakresu usług. Uważnie słuchamy naszych gości, wychodzimy naprzeciw ich oczekiwaniom, wdrażamy nowe rozwiązania. Pozwala nam to cieszyć się dobrą opinią, budowaną na bazie zaufania i elastyczności. Odwiedzając nas macie Państwo pewność, że jest to miejsce prowadzone przez pasjonatów agroturystyki.</p>
                    <p>Udryn to nasze miejsce na ziemi. Zapraszamy do nas i proponujemy Państwu:</p>
                    <ul>
                        <li>agroturystykę oraz wczasy w otoczeniu przyrody,</li>
                        <li>pokoje do wynajęcia,&nbsp;</li>
                        <li>noclegi,</li>
                        <li>kwatery pracownicze,</li>
                        <li>wynajem sali na konferencje i szkolenia,</li>
                        <li>imprezy okolicznościowe,</li>
                        <li>naukę jazdy konnej,</li>
                        <li>rowery,</li>
                        <li>kajaki,</li>
                        <li>wędkarstwo.</li>
                    </ul>
                    <p>Wykwalifikowani i doświadczeni pracownicy sprostają każdemu zadaniu, aby Państwa pobyt w ośrodku "Na Wzgórzu" obfitował w radosne wydarzenia, błogi relaks, lub aktywnie spędzony czas – zależnie od potrzeb i oczekiwań, a każdy nocleg przynosił upragniony wypoczynek.</p>
                    <h2>Pokoje do wynajęcia dla każdego</h2>
                    <p>Jeśli szukacie Państwo idealnego miejsca na spędzenie urlopu czy weekendu o dowolnej porze roku, nie warto szukać dalej. U nas dorośli mają wiele możliwości aby spożytkować energię, między innymi poprzez grę w piłkarzyki, czy ćwicząc z workiem treningowym, a dzieci z radością grają w cymbergaja i korzystają ze świetnie wyposażonego placu zabaw.</p>
                    <p>Dobrze czują się u nas także całe rodziny korzystające z możliwości wynajęcia pokoi. Blisko tu z Suwałk, Augustowa, Sejn, Jeleniewa, czy Szelmentu. Dogodna lokalizacja to także duży atut.</p>
                    <p style={{textAlign: 'center'}}>Zapraszamy do obejrzenia krótkiego filmu o naszym ośrodku.</p>
                    <p id="video" style={{textAlign: 'center'}}>
                        <iframe class="responsive-iframe" src="https://player.vimeo.com/video/52639941" title="Video" frameborder="0" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>

                    </p>
                </div>
            }
            no
        />

    )

}

export default Home;